<template>
  <transition name="slide-fadeUp" mode="out-in">
    <div v-if="isActive"
         class="bc-ordered-product-popup">
      <div class="info">
        <img class="info__img"
             :src="lastCartItem.picture_small" alt="product-picture">
        <div class="info__details">
          <div class="info__details__title">
            {{ lastCartItem.title }}
          </div>
          <div class="info__details__option">
            <div class="info__details__option__price">{{ itemPrice }}&nbsp;{{ $t('INTERFACE.CURRENCY') }}</div>
            <div class="info__details__option__measure" v-if="lastCartItem.preferred_option">
              {{ lastCartItem.preferred_option.title }}&nbsp;{{ lastCartItem.measure }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="total">
        <div class="total__title">{{ $t('CART.TOTAL_PRICE') }}</div>
        <div class="total__cost">{{ cartTotal }}&nbsp;{{ $t('INTERFACE.CURRENCY') }}</div>
      </div>
      <div class="action">
        <bc-router-link :to="'/cart'" class="action__to-order">
          <bc-button>{{ $t('CART.TO_ORDER') }}</bc-button>
        </bc-router-link>
        <bc-button class="action__continue-shopping" @click="continueShopping" :color-type="'white'">
          {{ $t('CART.CONTINUE_SHOPPING') }}
        </bc-button>
      </div>
    </div>
  </transition>
</template>

<script>
import {numberWithSpaces} from "@/utils";

export default {
  name: "bc-ordered-product-popup",
  components: {},
  computed: {
    lastCartItem() {
      let cartItems = this.$store.getters.cartItems
      return cartItems[cartItems.length - 1]
    },
    itemPrice() {
      return numberWithSpaces(this.lastCartItem?.price)
    },
    cartTotal() {
      return numberWithSpaces(this.$store.getters.cartTotal)
    },
    isActive() {
      return this.$store.getters.getIsActiveOrderPopup
    }
  },
  methods: {
    continueShopping() {
      this.$store.commit('setIsActiveOrderPopup', false)
    }
  }
}
</script>

<style scoped lang="scss">
.bc-ordered-product-popup {
  position: fixed;
  top: calc(var(--header-height) + 10px);
  right: 40px;
  width: 380px;
  padding: 20px;
  border: 1px solid $black;
  background: $white-backdrop;
  backdrop-filter: blur(10px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-size: .85em;

  .info {
    display: flex;
    margin-bottom: 20px;

    &__img {
      width: 80px;
      height: 80px;
      border: $light-gray 1px solid;
      margin-right: 20px;
      background: $white;
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1.5em;
      &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow-y: hidden;
      }

      &__option {
        display: flex;
        margin-top: 5px;

        &__price {
          font-size: 1.1em;
          margin-right: 10px;
        }

        &__measure {
          color: $dark-gray;
        }
      }
    }
  }

  hr {
    width: 100%;
    border-color: $light-gray;
    border-top: 0;
    margin: 0;
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    &__cost {
      font-size: 1.2em;
    }
  }

  .action {
    font-size: .95em;
    display: flex;
    justify-content: space-between;

    &__to-order, &__continue-shopping {
      width: 46%;
    }
    &__to-order button {
      width: 100%;
    }
  }


}
@media screen and (max-width: 960px) {
  .bc-ordered-product-popup {
    width: 340px;
  }
}
@media screen and (max-width: 600px) {
  .bc-ordered-product-popup {
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>