<template>
  <div class="bc-category">
    <div class="bc-category__title" v-html="category.title"></div>

    <div class="container-prev-next">
      <div :class="`arrow-left prev-${category.id}`">
        <img src="@/assets/img/icons/back.svg" alt="prev-icon">
      </div>
      <div :class="`arrow-right next-${category.id}`">
        <img src="@/assets/img/icons/back.svg" alt="next-icon">
      </div>
      <swiper
          :modules="modules"
          :slides-per-view="1"
          :watch-overflow="true"
          :breakpoints="{
          '500': {
            slidesPerView: 2,
          },
          '700': {
            slidesPerView: 3,
          },
          '960': {
            slidesPerView: 4,
          },
          '1600': {
            slidesPerView: 5,
          },
        }"
          :navigation="{nextEl: `.next-${category.id}`, prevEl: `.prev-${category.id}`}"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="bc-category__scroller"
      >
        <swiper-slide
            class="bc-category__scroller__slide"
            v-for="product in category.products"
            :key="product.id">
          <bc-product-card
              class="bc-category__scroller__slide__card"
              :product="product"
              @show-details="showProductDetails"></bc-product-card>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

import bcProductCard from "@/components/UI/cards/bc-product-card";

import {Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.scss'

export default {
  name: "bc-category",
  setup() {
    // const onSwiper = (swiper) => {
    const onSwiper = () => {
      // console.log('swiper');

    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
    };
  },
  props: ['category',],
  components: {
    bcProductCard,
    Swiper,
    SwiperSlide,
  },
  methods: {
    showProductDetails(productId) {
      console.log(productId)
    },
  },
}
</script>

<style scoped lang="scss">
.bc-category {
  margin-top: 50px;

  .container-prev-next .arrow-left.swiper-button-disabled,
  .container-prev-next .arrow-right.swiper-button-disabled {
    display: none !important;
  }

  &__title {
    font-size: 2.25em;
    text-align: center;
    margin-bottom: 100px;
  }
  .container-prev-next {
    position: relative;
    .arrow-left, .arrow-right {
      width: 30px;
      position: absolute;
      height: 60%;
      display: flex;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: .3;
      }
    }
    .arrow-left {
      left: 0;
    }
    .arrow-right {
      transform: rotate(180deg);
      right: 0;

    }
  }


  &__scroller {
    margin: 0 30px;

    &__slide {
    }
  }
}

@media screen and (max-width: 1600px) {
  .bc-category {
    &__scroller {
      &__slide {
        //max-width: 24%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .bc-category {
    &__scroller {
      &__slide {
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .bc-category {
    margin-top: 60px;

    &__title {
      margin-bottom: 50px;
    }

    &__scroller {
      &__slide {
        //max-width: 45%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .bc-category {
    &__scroller {
      &__slide {
        //max-width: 100%;
        &__card {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>
