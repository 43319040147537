<template>
  <div class="bc-shop">
    <div class="component component__padding">
      <div class="bc-shop__banner"
           :style="{ backgroundImage: 'url('+bannerTopPicture+')' }">
        <div class="bc-shop__banner__title">{{ bannerTop.title }}</div>
        <div class="bc-shop__banner__subtitle">{{ bannerTop.subtitle }}</div>
      </div>
      <bc-category
          v-for="category in categoriesList" :key="category.id"
          :category="category">
      </bc-category>
      <bc-ordered-product-popup/>
    </div>

  </div>
</template>

<script>
import {computed} from "vue";

import bcCategory from "@/components/UI/shop/bc-category";
import BcOrderedProductPopup from "@/components/UI/dialogs/bc-ordered-product-popup";
// import {usePageTitle} from "@/utils";
import {store} from "@/store";
import {useHead} from "@vueuse/head";

export default {
  name: "bc-shop",
  components: {
    BcOrderedProductPopup,
    bcCategory,
  },
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('SHOP')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('SHOP')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('SHOP')?.title),
    //     computed(() => store.getters.getSeoDataForPage('SHOP')?.description))
    this.fetchData()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  computed: {
    // isActiveOrderPopup() {
    //   console.log(this.$store.getters.IsActiveOrderPopup, 'alo>?')
    //   return this.$store.getters.getIsActiveOrderPopup
    // },
    bannerTopPicture() {
      return this.windowWidth > 500 ? this.bannerTop.picture : this.bannerTop.picture_small
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.$store.commit('setIsActiveOrderPopup', false)
  },
  methods: {
    fetchData() {
      this.$http.get('/api/shop/').then(resp => {
        this.categoriesList = resp.data.categories
        this.bannerTop = resp.data.banner_top
      }).catch(err => {
        this.error = true
        console.error(err)
      })
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      bannerTop: {},
      categoriesList: null,
    }
  }
}
</script>

<style scoped lang="scss">
.bc-shop {
  .component {
    position: relative;
  }
  &__banner {
    width: 100%;
    min-height: 235px;
    padding: 50px;
    //background-color: #FCF8F6;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-size: 1.8em;
      max-width: 60%;
    }

    &__subtitle {
      font-size: .9em;
      max-width: 60%;
      line-height: 30px;
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: 901px) {
  .bc-shop {
    .component {
      padding-top: calc(var(--header-height) + 50px);
    }
  }
}

@media screen and (max-width: 900px) {
  .bc-shop {
    &__banner {
      padding: 20px;

      &__title, &__subtitle {
        max-width: 80%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .bc-shop {
    &__banner {
      min-height: 364px;
      background-position: bottom;
      padding: 20px;
      justify-content: flex-start;

      &__title, &__subtitle {
        max-width: 100%;
      }
      &__subtitle {
        margin-top: 10px;
      }
    }
  }
}
</style>