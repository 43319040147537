<template>
  <div class="margin-container">
    <div class="bc-product-card">
      <bc-router-link :to="productSlug">
        <img class="bc-product-card__img" :src="product?.picture_small" :alt="`bc-product-${product.title}`"
        >
      </bc-router-link>

      <bc-router-link :to="productSlug">
        <div class="bc-product-card__title" v-html="product.title"></div>
      </bc-router-link>
      <div class="bc-product-card__subtitle" v-html="subtitle"></div>
      <div class="bc-product-card__price" v-if="price">{{ price }}&nbsp;{{ $t('INTERFACE.CURRENCY') }}</div>
      <bc-button
          class="bc-product-card__btn"
          :color-type="isInCart ? 'white' : 'black'"
          @click="addToCard">
        <span v-if="isInCart">{{ $t('SHOP.IN_CART') }}</span>
        <span v-else>{{ $t('SHOP.BUY') }}</span>
      </bc-button>
    </div>
  </div>

</template>

<script>

import {numberWithSpaces} from "@/utils";

export default {
  name: "bc-product-card",
  props: ['product'],
  computed: {
    preferredProductOption() {
      if (this.product.options.length) {
        return this.product.options[0]
      }
      return null
    },
    subtitle() {
      if (this.preferredProductOption) {
        return `${this.preferredProductOption.title}&nbsp;${this.product.measure}`
      }
      return '&nbsp;'
    },
    price() {
      if (this.product.options.length) {
        return numberWithSpaces(this.product.options[0].price)
      }
      return numberWithSpaces(this.product.price)
    },
    isInCart() {
      return !!this.$store.getters.cartItems.find(
          i => i.id === this.product.id
              && (i.preferred_option?.id === this.preferredProductOption?.id)
      )
    },
    productSlug() {
      return `/shop/${this.product.slug}`
    }
  },

  methods: {
    addToCard() {
      if (this.isInCart) {
        return
      }

      this.$store.commit('addToCart', {
        product: {
          id: this.product.id,
          picture_small: this.product.picture_small,
          title: this.product.title,
          preferred_option: this.preferredProductOption,
          measure: this.product.measure,
          price: this.preferredProductOption?.price || this.product.price,
          slug: this.product.slug
        },
        amount: 1
      })
      this.$store.commit('setIsActiveOrderPopup', true)

    },
  }
}
</script>

<style scoped lang="scss">
.margin-container {
  margin: 0 3px;
}

.bc-product-card {
  user-select: none;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f9f9f9;
    .bc-product-card__img {
      transform: scale(1) !important;
    }
  }

  &__img {
    cursor: pointer;
    max-width: 300px;
    width: 100%;
    align-self: center;
    transform: scale(0.9);
    transition: transform .2s;
  }

  &__title {
    cursor: pointer;
    margin-top: 20px;
    font-size: 1em;
    min-height: 2.5em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    padding: 0 10px;
  }

  &__subtitle {
    margin-top: 10px;
    color: $dark-gray;
    font-size: .75em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    padding: 0 10px;
  }

  &__price {
    margin-top: 16px;
    padding: 0 10px;
  }

  &__btn {
    margin-top: 20px;
    opacity: 0;
    width: 100%;
    transition: all .3s;
  }

  &:hover {
    .bc-product-card__btn {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 960px) {
  .bc-product-card {
    &__btn {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 600px) {
  .bc-product-card {
    &__title {
      font-size: 1.1em;
    }
  }
}
</style>
